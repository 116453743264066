<template lang="pug">
    .main-wrapper
        ProgressBar(v-if='waiting' mode="indeterminate")
        div(v-else)
            header.main-wrapper-header
                .p-grid.p-align-center
                    .p-col-3
                        Button.p-button-secondary.btn-back(icon='jam jam-chevrons-left' @click='$router.go(-1)')
                    .p-col-9.ta-right
                        h1.text-header.text-secondary MedClub /
                            router-link(:to='{ path: "/albuns" }') Álbuns /
                            b {{ model.id ? 'Editar' : 'Adicionar' }}

            Panel(:header="`${model.id ? 'Editar' : 'Adicionar'} Álbum`")
                .div
                    .p.col-12.mb-3
                        label.form-label Título do Álbum: *
                        InputText.p-inputtext-lg(
                        type="text",
                        v-model="model.name",
                        required
                    )

                    FileUpload(
                        v-if='!waitingAnexosAlbum'
                        style="display: fixed",
                        chooseLabel="Escolher Arquivo",
                        cancelLabel="Cancelar",
                        @select="uploadAnexoAlbuns",
                        uploadLabel='Enviar'
                        :multiple="true",
                        url='/'
                        @remove='removeImage'
                        @before-send='beforeSend'
                        accept="image/*",
                        :maxFileSize="1000000"
                        )
                        template(#empty)
                            p Arraste e solte a imagem aqui para realizar upload

                    .p-grid.mt-2
                    DataTable(:autoLayout='true' :value="anexosImagem", :selection.sync="selected", dataKey="id")
                        Column(
                        selectionMode="multiple",
                        bodyStyle="text-align: center;",
                        headerStyle="width: 3em"
                        )
                        Column(
                        headerStyle="width: 5%;",
                        bodyStyle="text-align: center;",
                        field="id",
                        header="Código"
                        )

                        Column(
                        headerStyle="width: 10%;",
                        bodyStyle="text-align: center;",
                        field="attach",
                        header="Imagem"
                        )
                            template(#body="props")
                                img(
                                    v-bind:src='props.data.imagem_format'
                                    v-bind:alt="props.data.title",
                                    height="80",
                                    width="80"
                                )

                        Column(
                        headerStyle="width: 70%;",
                        bodyStyle="text-align: center;",
                        field="title",
                        header="Título"
                        )

                        Column(headerStyle="width: 10%; text-align: center")
                        template(#header) Ações
                        template(#body="props")
                            .ta-center
                            //- Button.p-button-raised.p-button-rounded.mr-1(
                            //-     v-tooltip.top="'Visualizar'"
                            //-     icon="jam jam-document"
                            //-     @click="view(props.data.id)"
                            //- )
                            //- Button.p-button-raised.p-button-rounded.mr-1(
                            //-     v-tooltip.top="'Editar'"
                            //-     icon="jam jam-write"
                            //-     @click="edit(props.data.id)"
                            //- )
                            Button.p-button-raised.p-button-rounded.p-button-danger(
                                v-tooltip.top="'Apagar'",
                                icon="jam jam-minus-circle",
                                @click="dialogApagar(props.data.id)"
                            )

                    Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

                    .p-col-12.ta-right
                        Button.buttonSave(
                            v-if="!waitingSalvar",
                            label="Salvar",
                            type="submit",
                        )

</template>
<style scoped>
    .buttonSave {
        width: 30%;
    }
</style>
<script>
import ProgressBar from "primevue/progressbar"
import Button from 'primevue/button'
import InputText from 'primevue/inputtext'
import TextArea from 'primevue/textarea'
import ProgressSpinner from 'primevue/progressspinner'
import Panel from 'primevue/panel'
import Dropdown from 'primevue/dropdown'
import Noticias from '../../middleware/controllers/Noticias'
import RadioButton from 'primevue/radiobutton';
import Editor from 'primevue/editor';
import Column from "primevue/column";
import FileUpload from '../Noticias/FileUpload.vue';
import Paginator from 'primevue/paginator';
import Calendar from 'primevue/calendar';
import DataTable from "primevue/datatable";
// import { converteDataParaFront, converteDataParaAPI } from '../../utils.js'

export default {
    data() {
        return {
            waiting: false,
            model: {},
            categorias: [],
            formato: 'text',
            data: '',
            waitingSalvar: false,
            waitingAnexosAlbum: false,
            paginator: {
                page: this.$route.query.pg ? this.$route.query.pg : 1,
                per_page: 10,
                count: 0
            },
            selected: [],
            anexosImagem: [],
            categoria: {}
        }
    },
    components: { ProgressBar, Column, Paginator, DataTable, Button, InputText, ProgressSpinner, Panel, TextArea, Dropdown, RadioButton, Editor, FileUpload, Calendar },
    created() {
        this.waiting = true

        //Login na nova API
        Noticias.login().then(result => {
            this.tokenAPI = result;

            let id = 0;
            if (this.$route.params.id !== undefined) {
                if (!isNaN(this.$route.params.id))
                    id = parseInt(this.$route.params.id);

                        Noticias.getAlbumById(id, this.tokenAPI).then((response) => {
                            this.model = response[0];
                            // console.log(response);
                            // this.data = converteDataParaFront(this.model.publish_date);
                            // this.categoria = this.categorias.filter(
                            // (categoria) => categoria.id === this.model.categories.id
                            // )[0];
                            this.waiting = false;
                        });
                    } else {
                    this.waiting = false;
                }

            Noticias.getAttachments(this.tokenAPI, this.paginator.page).then((response) => {
                if (!response) this.anexos = []
                else {
                // Ordena a lista pelo id em ordem decrescente
                // response.rows.sort((a, b) => {
                //     if(a.id < b.id) return 1
                //     else return -1
                // })
                response.rows.forEach(element => {
                    element.imagem_format = 'https://' + element.attach
                    if(element.attachmentsType && element.attachmentsType.name == 'Imagem') {
                        this.anexosImagem.push(element);
                    }
                })
                this.paginator.count = response.total
                }
                // this.waiting = false
            });
        })
    },
    methods: {
        onPage(event) {
            this.paginator.page = event.page + 1;
            Noticias.getAttachments(this.tokenAPI, this.paginator.page).then((response) => {
                if (!response) this.anexos = [];
                else {
                // Ordena a lista pelo id em ordem decrescente
                response.rows.sort((a, b) => {
                    if(a.id < b.id) return 1
                    else return -1
                })
                this.anexosImagem = [];
                response.rows.forEach(element => {
                    element.imagem_format = 'https://' + element.attach
                    if(element.attachmentsType && element.attachmentsType.name == 'Imagem') {
                        this.anexosImagem.push(element);
                    }
                  })
                }

            });
        },
        /*uploadAnexoAlbuns(event) {
            console.log(event);

        },
        removeImage(event) {
            console.log(event);
        },
        beforeSend(event) {
            console.log(event);
        }*/
    }
}
</script>
